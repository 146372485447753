import React, { useCallback, useContext } from "react";

import { cameras } from "../lib/protocols/streams";
import { CameraContext, CAM_VIEW_MAIN } from "./CameraProvider";
import { SubMenu } from "./Menu";
import { onClickAndTouchEnd } from "../lib/utils";
import { useArmControl } from "./ArmControlProvider";
import { useSocket } from "./ControlSocketProvider";
import styles from "./css/Menu.module.css";

function CameraPanel({ onClose }) {
    const { views, selectSource } = useContext(CameraContext);
    const { controlSocket: socket, sendControl } = useSocket();
    const { inArmControl } = useArmControl();

    const selectSource2 = useCallback(
        (sourceName) => {
            sendControl(["/ros_media_stream/operator_stream/select_source", sourceName]);
        },
        [sendControl]
    );

    return (
        <SubMenu title="Switch Camera" onClose={onClose}>
            <div className={styles["camera-grid"]}>
                {cameras.map((c) => {
                    const selected = views[CAM_VIEW_MAIN] === c.source;
                    return (
                        <button
                            key={c.name}
                            type="button"
                            {...onClickAndTouchEnd(() => {
                                // TODO: possible logic error? should want arm cam when in arm control but this bans selecting arm cam when inArmControl is true
                                if (inArmControl && c.source === `arm`) return;
                                selectSource(CAM_VIEW_MAIN, c.source); // old ros_media_stream  : send msg to ros_media_stream own websocket
                                selectSource2(c.source); // new ros_media_stream2 : send msg to web_rosproxy
                            })}
                            className={`${styles.camera} ${selected && styles.selected}`}
                        >
                            {c.name}
                        </button>
                    );
                })}
            </div>
        </SubMenu>
    );
}

export default React.memo(CameraPanel);
