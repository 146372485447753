import React, { useContext, useState } from "react";
import { DEFAULT_HOST, DEFAULT_WEBSERVER_PORT } from "../consts";
import {
    missionDeleteArtRef,
    missionDetectArtDmg,
    missionListArtRef,
    missionLockInspection,
    missionPrepareArtRef,
    missionTestCmd,
} from "../lib/protocols/control";
import { callService, callSpotService } from "../lib/service";
import { dummyArtPieces } from "../testdata";
import { useSnack } from "./SnackProvider";

export const InspectionContext = React.createContext({});
InspectionContext.displayName = "Inspection";
export const InspectionConsumer = InspectionContext.Consumer;
export const useInspection = () => useContext(InspectionContext);

export const normalizeArtRefLink = (filename) => {
    return `${window.location.protocol}//${DEFAULT_HOST}:${DEFAULT_WEBSERVER_PORT}/art_inspection/reference/${filename}`;
};
export const INVALID_CUSTOM_ACTION = "INVALID_CUSTOM_ACTION";
export const RECORD_NOT_STARTED = "RECORD_NOT_STARTED";
export const CUSTOM_ACTION_ADDED = "CUSTOM_ACTION_ADDED";
export const ADD_CUSTOM_ACTION_FAILED = "ADD_CUSTOM_ACTION_FAILED";

const USE_DUMMY = false;

function InspectionProvider({ children }) {
    const { addToast } = useSnack();
    const [inspectionData, setInspectionData] = useState([]);
    const [error, setError] = useState(null);
    const [preparing, setPreparing] = useState(false);

    const errHandler = (e, message) => {
        console.error("Error occured. message: ", message, "; error thrown: ", e);
        addToast({
            type: "error",
            message: message,
            duration: 7000,
        });
        setError(message);
    };

    const getArtPieces = () => {
        setError(null);
        if (USE_DUMMY) setInspectionData(dummyArtPieces);
        else {
            return callService(...missionListArtRef())
                .then((arts) => {
                    const toDisplay = arts.map(({ ref_img, art_id }) => {
                        return {
                            id: art_id,
                            path: normalizeArtRefLink(ref_img),
                            desc: `art ${art_id}`,
                        };
                    });

                    setInspectionData(toDisplay);
                })
                .catch((e) => {
                    errHandler(e, `Failed to get art references: ${e.message || "Unknown error"}`);
                });
        }
    };

    const deleteArtRef = (art_id) => {
        return callService(...missionDeleteArtRef(art_id))
            .then(() => {
                addToast({
                    type: "success",
                    message: `Art reference ${art_id} deleted`,
                    duration: 7000,
                });
            })
            .catch((e) => {
                errHandler(e, `Failed to delete art reference: ${e.message || "Unknown error"}`);
            });
    };

    const prepareArtRef = () => {
        addToast({
            type: "info",
            message: `Preparing art ref, please don't move spot.`,
            duration: 7000,
        });
        setPreparing(true);
        setError(null);
        return callService(...missionPrepareArtRef())
            .then((results) => {
                addToast({
                    type: "success",
                    message: `Art reference saved, id: ${results["art_id"]}`,
                    duration: 7000,
                });
            })
            .catch((e) => {
                errHandler(e, `Could not prepare art reference: ${e.message}`);
            })
            .finally(() => {
                setPreparing(false);
            });
    };

    const addDetectDmg = (art_id) => {
        return callSpotService(...missionDetectArtDmg(art_id))
            .then((res) => {
                console.log("addDetectImg res:", res);
                addToast({
                    type: "success",
                    message: `Added custom action: detect art damage.`,
                    duration: 7000,
                });
            })
            .catch((e) => {
                errHandler(e, `Could not add detect art damage action: ${e.message}`);
            });
    };

    const addPTZInspect = (ptzMovement) => {
        return callSpotService(...missionLockInspection(ptzMovement))
            .then((res) => {
                console.log(`PTZ Inspection: ${res}`);
                addToast({
                    type: "success",
                    message: `Added custom action: lock inspection.`,
                    duration: 7000,
                });
            })
            .catch((e) => {
                errHandler(e, `Could not add lock inspect action: ${e.message}`);
            });
    };

    const testCmd = () => {
        return callSpotService(...missionTestCmd())
            .then(() => {
                addToast({
                    type: "success",
                    message: `Test Custom Action added`,
                    duration: 7000,
                });
            })
            .catch((errmsg) => {
                console.log(errmsg.toString());
                addToast({
                    type: "error",
                    message: `Failed to add Test Custom Action`,
                    duration: 7000,
                });
            });
    };

    return (
        <InspectionContext.Provider
            value={{
                preparing,
                inspectionData,
                deleteArtRef,
                getArtPieces,
                prepareArtRef,
                addDetectDmg,
                error,
                testCmd,
                addPTZInspect,
            }}
        >
            {children}
        </InspectionContext.Provider>
    );
}

export default InspectionProvider;
