import uniq from "lodash/uniq";
import React from "react";

import { defaultCam, getCamera, selectStreams } from "../lib/protocols/streams";
import { STATE_STREAM_NONE, STATE_STREAM_OKAY, STATE_STREAM_SLOW } from "../lib/state";

import { DEFAULT_HOST } from "../consts";
import { Janus } from "../lib/janus";

export const JanusWebRTCContext = React.createContext({
    camConnected: false,
    streamStatuses: new Map(),
});

JanusWebRTCContext.displayName = "JanusWebRTCContext";

export const JanusWebRTCContextConsumer = JanusWebRTCContext.Consumer;

class JanusWebRTCProvider extends React.Component {
    constructor(props) {
        super(props);

        //handle to janus session
        this.janus = null;

        // the Janus streaming endpoint could publish serveral streams
        // for each stream we subscribe, we create a separate streaming plugin handle
        this.streamingPluginHandles = new Map();
        this.streamStatuses = new Map();

        // The Janus streaming endpoint can have serveral streams (eg. BBC, CBS)
        this.streamsList = [];
        this.streamsToConnectOnStart = ["operator_stream", "PTZ Cam stream fallback"];
        // this.streamsToConnectOnStart = ["operator_stream"];

        this.server = "https://" + DEFAULT_HOST + ":8098/janus";

        this.iceServers = null; // if null defaults to "stun:stun.l.google.com:19302"

        this.state = {
            // to be exported as JanusWebRTCContext values
            camConnected: false,
            streamStatuses: this.streamStatuses,
        };
    }

    componentDidMount() {
        this.connect();
    }

    componentWillUnmount() {
        this.disconnect();
    }

    disconnect() {
        console.log(`JanusWebRTCProvider disconnect from ${this.server}`);
        for (const streamKey of this.streamingPluginHandles.keys()) {
            this.stopStream(streamKey);
        }

        this.streamStatuses.clear();
        this.setState({
            camConnected: false,
            streamStatuses: this.streamStatuses,
        });
    }

    async attachStreamingPlugin(streamDescriptionOrId, startStreamAfterAttach = true) {
        var self = this;

        const promise = new Promise((resolve, reject) => {
            self.janus.attach({
                plugin: "janus.plugin.streaming",
                success: function (pluginHandle) {
                    Janus.log(`streamingPluginHandle ${streamDescriptionOrId} created`);

                    self.streamingPluginHandles.set(streamDescriptionOrId, pluginHandle);
                    self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_NONE);

                    pluginHandle.resolvedStreamId = null; // to be filled at first call of startStream
                    pluginHandle.mediaStream = null; // to be filled by onRemoteTrack callback

                    if (startStreamAfterAttach) self.startStream(streamDescriptionOrId);

                    resolve();
                },
                error: function (error) {
                    Janus.error(
                        `streamingPluginHandle ${streamDescriptionOrId} creation failed with error: `,
                        error
                    );
                    reject();
                },
                iceState: function (state) {
                    Janus.log(
                        `streamingPluginHandle ${streamDescriptionOrId} ICE state changed to ${state}`
                    );
                },
                webrtcState: function (on) {
                    Janus.log(
                        `streamingPluginHandle ${streamDescriptionOrId} WebRTC PeerConnection is ${
                            on ? "up" : "down"
                        } now`
                    );
                },
                slowLink: function (uplink, lost, mid) {
                    Janus.warn(
                        `streamingPluginHandle ${streamDescriptionOrId} reports problems ${
                            uplink ? "sending" : "receiving"
                        } packets)`
                    );
                    self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_SLOW);
                },
                onmessage: function (msg, jsep) {
                    Janus.debug(
                        `streamingPluginHandle ${streamDescriptionOrId} got a message: `,
                        msg
                    );
                    var result = msg["result"];
                    if (result) {
                        if (result["status"]) {
                            var status = result["status"];
                            if (status === "starting")
                                Janus.log(
                                    `streamingPluginHandle ${streamDescriptionOrId} stream starting, please wait...`
                                );
                            else if (status === "started")
                                Janus.log(
                                    `streamingPluginHandle ${streamDescriptionOrId} stream started`
                                );
                            else if (status === "stopped") {
                                Janus.log(
                                    `streamingPluginHandle ${streamDescriptionOrId} stream stopped from server side`
                                );
                                self.stopStream(streamDescriptionOrId);
                            }
                        } else if (msg["streaming"] === "event") {
                            // Does this event refer to a mid in particular? mid: mediaID (eg a1 a2 for audio L, R)
                            var mid = result["mid"] ? result["mid"] : "0";
                            // Is simulcast in place?
                            var substream = result["substream"];
                            var temporal = result["temporal"];
                            if (
                                (substream !== null && substream !== undefined) ||
                                (temporal !== null && temporal !== undefined)
                            ) {
                                Janus.warn("Stream has Simulcast but Simulcast UI not implemented");
                            }
                            // Is VP9/SVC in place?
                            var spatial = result["spatial_layer"];
                            temporal = result["temporal_layer"];
                            if (
                                (spatial !== null && spatial !== undefined) ||
                                (temporal !== null && temporal !== undefined)
                            ) {
                                Janus.warn("Stream has SVC but SVC UI not implemented");
                            }
                        }
                    } else if (msg["error"]) {
                        Janus.error(`streamingPluginHandle ${streamDescriptionOrId} get error`);
                        Janus.error(msg);
                        // self.stopStream(streamDescriptionOrId);
                        return;
                    }
                    if (jsep) {
                        Janus.debug(
                            `streamingPluginHandle ${streamDescriptionOrId} Handling SDP from server`,
                            jsep
                        );
                        var stereo = jsep.sdp.indexOf("stereo=1") !== -1;
                        // Offer from the plugin, let's answer
                        self.streamingPluginHandles.get(streamDescriptionOrId).createAnswer({
                            jsep: jsep,
                            // We only specify data channels here, as this way in
                            // case they were offered we'll enable them. Since we
                            // don't mention audio or video tracks, we autoaccept them
                            // as recvonly (since we won't capture anything ourselves)
                            tracks: [{ type: "data" }],
                            customizeSdp: function (jsep) {
                                if (stereo && jsep.sdp.indexOf("stereo=1") === -1) {
                                    // Make sure that our offer contains stereo too
                                    jsep.sdp = jsep.sdp.replace(
                                        "useinbandfec=1",
                                        "useinbandfec=1;stereo=1"
                                    );
                                }
                            },
                            success: function (jsep) {
                                Janus.debug("Got SDP!", jsep);
                                var body = { request: "start" };
                                self.streamingPluginHandles
                                    .get(streamDescriptionOrId)
                                    .send({ message: body, jsep: jsep });
                            },
                            error: function (error) {
                                Janus.error("WebRTC error:", error);
                            },
                        });
                    }
                },
                onremotetrack: function (track, mid, on) {
                    var streamingPluginHandle =
                        self.streamingPluginHandles.get(streamDescriptionOrId);

                    Janus.debug(
                        `streamingPluginHandle ${streamDescriptionOrId} Remote track (mid=${mid}) ${
                            on ? "added" : "removed"
                        }`,
                        track
                    );

                    if (track.kind === "audio") {
                        Janus.debug("audio track not treated yet");
                        return;
                    }

                    if (track.kind === "video") {
                        //FIXME? we assume the source only has 1 video track

                        // WHY same remote track appear twice? first with track.muted = true, then false
                        if (on && !track.muted) {
                            Janus.log(
                                `streamingPluginHandle ${streamDescriptionOrId} Created remote track:`,
                                mid
                            );
                            var stream = new MediaStream([track]);
                            streamingPluginHandle.mediaStream = stream;
                            self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_OKAY);
                            self.setState({ streamStatus: self.streamStatuses });
                        } else {
                            Janus.log(
                                `streamingPluginHandle ${streamDescriptionOrId} Removed remote track:`,
                                mid
                            );
                            streamingPluginHandle.mediaStream = null;
                            self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_NONE);
                            self.setState({ streamStatus: self.streamStatuses });
                        }
                    }
                },
                // FIXME? use webRTC data channel to control stream at the future?
                // ondataopen: function (data) {
                //     Janus.log("The DataChannel is available!");
                // },
                // ondata: function (data) {
                //     Janus.debug("We got data from the DataChannel!", data);
                // },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification :::");
                    self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_NONE);
                    self.setState({ streamStatus: self.streamStatuses });
                },
            });
        });

        return promise;
    }

    connect() {
        var self = this;
        console.log(`JanusWebRTCProvider connect to ${self.server} ${self.iceServers}`);

        Janus.init({
            debug: true, // !!! toggle showing Janus.log msg in browser console
            dependencies: Janus._useDefaultDependencies(), // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
            callback: function () {
                self.janus = new Janus({
                    server: self.server,
                    iceServers: self.iceServers,
                    // Should the Janus API require authentication, you can specify either the API secret or user token here too
                    //		token: "mytoken",
                    //	or
                    //		apisecret: "serversecret",
                    success: async function () {
                        self.setState({ camConnected: true });
                        await self.attachStreamingPlugin(
                            "FOR_INTERNAL_QUERY_USE_1259",
                            5000,
                            false
                        );
                        await self.updateStreamsList();
                        console.log("asdf streamsList", self.streamsList);

                        // Attach to Streaming plugin
                        for (const streamName of self.streamsToConnectOnStart) {
                            self.attachStreamingPlugin(streamName);
                        }
                    },
                    error: function (error) {
                        Janus.error(error);
                    },
                    destroyed: function () {
                        self.setState({ camConnected: false });
                        window.location.reload();
                    },
                });
            },
        });
    }

    async updateStreamsList() {
        var streamingPluginHandle = this.streamingPluginHandles.get("FOR_INTERNAL_QUERY_USE_1259");
        if (!streamingPluginHandle) return false;

        var self = this;
        var body = { request: "list" };

        const promise = new Promise((resolve, reject) => {
            streamingPluginHandle.send({
                message: body,
                success: function (result) {
                    if (!result || !("list" in result)) {
                        Janus.error("Got no response to our query for available streams");
                        return;
                    }

                    var streams = result["list"];
                    streams.sort((a, b) => {
                        return a.id < b.id ? -1 : 1;
                    });

                    Janus.log("Got a list of available streams:", streams);
                    for (const stream of streams) {
                        Janus.debug(` >> description: ${stream.description} id: ${stream.id}`);
                    }
                    self.streamsList = streams;
                    resolve();
                },
            });
        });

        return promise;
    }

    // resolve the unique ID of the stream as defiend by server side from the stream's string description
    resolveStreamId(streamDescriptionOrId) {
        var targetStreamId = null;
        for (const stream of this.streamsList) {
            if (
                streamDescriptionOrId === stream.id ||
                streamDescriptionOrId === stream.description
            ) {
                targetStreamId = stream.id;
                break;
            }
        }

        if (targetStreamId == null) {
            Janus.error(`Unknown stream ${streamDescriptionOrId}, available streams are:`);
            Janus.error(this.streamsList);
        }

        return targetStreamId;
    }

    async startStream(streamDescriptionOrId) {
        Janus.log(`startStream is called for ${streamDescriptionOrId}`);

        var handle = this.streamingPluginHandles.get(streamDescriptionOrId);

        if (!handle) {
            Janus.error(`Unknown / not registered stream ${streamDescriptionOrId}`);
            return false;
        }

        if (!handle.resolvedStreamId) {
            handle.resolvedStreamId = this.resolveStreamId(streamDescriptionOrId);
            if (handle.resolvedStreamId == null) await this.updateStreamsList();

            handle.resolvedStreamId = this.resolveStreamId(streamDescriptionOrId);
            if (handle.resolvedStreamId == null) {
                Janus.error(`Stream ${streamDescriptionOrId} not published on server side`);
                return false;
            }
        }

        var body = {
            request: "watch",
            id: parseInt(handle.resolvedStreamId),
            //  pin    : "1234",
            //  media  : [] <array of mids to subscribe to, as strings; optional, missing or empty array subscribes to all mids>
        };

        handle.send({ message: body });
        handle.bitrateMonitor = this.makeBitrateMonitorTask(streamDescriptionOrId);

        return true;
    }

    stopStream(streamDescriptionOrId) {
        Janus.log(`stopStream is called for ${streamDescriptionOrId}`);

        var handle = this.streamingPluginHandles.get(streamDescriptionOrId);
        if (!handle) {
            Janus.error(`Unknown / not registered stream ${streamDescriptionOrId}`);
            return;
        }

        var body = { request: "stop" };
        handle.send({ message: body });
        handle.hangup();

        clearInterval(handle.bitrateMonitor);
        this.streamingPluginHandles.delete(streamDescriptionOrId);
    }

    getStream(streamDescriptionOrId) {
        var handle = this.streamingPluginHandles.get(streamDescriptionOrId);

        if (!handle) {
            Janus.error(`getStream failed as stream '${streamDescriptionOrId}' do not exist`);
            return null;
        }

        if (!handle.mediaStream) {
            Janus.error(
                `getStream failed as stream '${streamDescriptionOrId}' exist but has no video track`
            );
            return null;
        }

        return handle.mediaStream;
    }

    makeBitrateMonitorTask(streamDescriptionOrId) {
        var self = this;
        var bitrateMonitor = setInterval(function () {
            var handle = self.streamingPluginHandles.get(streamDescriptionOrId);
            if (!handle) return;

            var bitrate = handle.getBitrate(); // a string: xxx kbits/sec"
            bitrate = parseInt(bitrate.split()[0]);

            if (bitrate > 0.0) {
                self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_OKAY);
            } else {
                self.streamStatuses.set(streamDescriptionOrId, STATE_STREAM_NONE);
            }
        }, 2000);

        return bitrateMonitor;
    }

    attachMediaStreamToElem(element, streamDescriptionOrId) {
        var stream = this.getStream(streamDescriptionOrId);

        if (stream === null) {
            return false;
        }

        try {
            Janus.error(`try attachMediaStreamToElem `, stream);
            element.srcObject = stream;
            return true;
        } catch (e) {
            // old browser support
            try {
                element.src = URL.createObjectURL(stream);
                return true;
            } catch (e) {
                Janus.error("Error attaching stream to element", e);
                return false;
            }
        }
    }

    render() {
        console.log("WebRTC provider camConnected", this.state.camConnected);

        return (
            <JanusWebRTCContext.Provider
                value={{
                    ...this.state,
                    getStream: this.getStream.bind(this),
                    attachMediaStreamToElem: this.attachMediaStreamToElem.bind(this),
                }}
            >
                {this.props.children}
            </JanusWebRTCContext.Provider>
        );
    }
}

export default JanusWebRTCProvider;
