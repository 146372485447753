import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

import { matchPath, withRouter } from "react-router-dom";
import { missionDelete, missionList, missionRename } from "../lib/protocols/control";
import { callSpotService } from "../lib/service";
import { subscribe } from "../lib/socket";
import {
    STATES_MISSION_END_STATES,
    STATES_RECORD_END_STATES,
    STATE_MISSION_PAUSED,
    STATE_MISSION_STARTING,
    STATE_MISSION_STOPPED,
    STATE_NAV_STARTING,
    STATE_NAV_STOPPED,
    STATE_NAV_LOADING,
    TOPIC_NAV_STATE,
    TOPIC_STATE_INSPECTION_RESULT,
    TOPIC_STATE_MISSION_RECORD,
    TOPIC_STATE_MISSION_RUN,
    TOPIC_NAV_CONTROL,
    STATE_MISSION_STARTED,
} from "../lib/state";
import { dummyMissions } from "../testdata";

export const MissionContext = React.createContext();
MissionContext.displayName = "Mission";
export const MissionConsumer = MissionContext.Consumer;
export const useMission = () => useContext(MissionContext);

const USE_DUMMY = false;

const useFindMissionIdFromRouteOnce = (location, setMissionId) => {
    useEffect(() => {
        const params = matchPath(location.pathname, "/main/mission/:missionId")?.params;
        if (!params) return null;
        const { missionId = null } = params;
        setMissionId(missionId);
    }, []);
};

function MissionProvider({ children, location, history }) {
    const { controlSocket: socket, sendControl } = useSocket();
    const { addToast, setSnack } = useSnack();
    const [recordState, setRecordState] = useState(STATE_NAV_STOPPED);
    const [recordPayload, setRecordPayload] = useState({});
    const [missionState, setMissionState] = useState(null);
    const [missionPayload, setMissionPayload] = useState({});

    const [missionId, setMissionId] = useState(null);
    const [mapId, setMapId] = useState(null);

    useFindMissionIdFromRouteOnce(location, setMissionId);

    useEffect(() => {
        if (!missionId) return;
        history.push({
            pathname: `/main/mission/${missionId}`,
        });
    }, [history, missionId]);

    const [missions, setMissions] = useState(null);
    const [recordEnded, setRecordEnded] = useState(true);
    const [missionEnded, setMissionEnded] = useState(true);
    const [inspection, setInspection] = useState(null);
    const [inspectionPayload, setInspectionPayload] = useState({});
    const [missionStarting, setMissionStarting] = useState(false);
    const [missionPaused, setMissionPaused] = useState(false);
    const [navState, setNavState] = useState(false);

    const listMission = (invalidateCurrent = false) => {
        // not loaded when missions is set to null
        invalidateCurrent && setMissions(null);
        subscribe(socket, "/mission/list", (data) => {
            setMissions(data["list"]);
        });
    };

    const deleteMission = (id) =>
        callSpotService(...missionDelete(id)).catch((err) => {
            throw new Error(`Could not delete mission: ${err.message}`);
        });

    const renameMission = (currName, newName) =>
        callSpotService(...missionRename(currName, newName)).catch((err) => {
            throw new Error(`Could not rename mission: ${err.message}`);
        });

    const handleRename = (oldId, newId, setError = () => {}) => {
        const newName = newId?.trim();
        setError(null);
        if (oldId !== newName) {
            if (newName === "") {
                setError("Mission name cannot be empty");
                return;
            }
            renameMission(oldId, newName)
                .then(() => {
                    setSnack({ message: `Changed Name to ${newName}` });
                    setRecordState(null);
                })
                .catch((err) => {
                    setError(err.message);
                });
        } else {
            setError("Same with original name");
        }
    };

    const handleNavState = () => {
        const data = navState ? STATE_NAV_STOPPED : STATE_NAV_STARTING;
        sendControl([TOPIC_NAV_CONTROL, { data: data }]);
    };

    const handleDelete = (id, setError = () => {}) => {
        setError(null);
        deleteMission(id)
            .then(() => {
                setSnack({ message: `Cancel saving record` });
            })
            .catch((err) => {
                setError(err.message);
            });
        setRecordState(STATE_NAV_STOPPED);
    };
    useEffect(() => {
        subscribe(socket, TOPIC_STATE_MISSION_RECORD, (data) => {
            setRecordEnded(data === STATE_NAV_STOPPED);
            if (
                data === STATE_NAV_STARTING ||
                data === STATE_NAV_STOPPED ||
                data === STATE_NAV_LOADING
            ) {
                setRecordState(data);
            }
        });

        subscribe(socket, TOPIC_STATE_MISSION_RUN, (data) => {
            setMissionEnded(data === STATE_MISSION_STOPPED);
            setMissionStarting(data === STATE_MISSION_STARTED);
            setMissionPaused(data === STATE_MISSION_PAUSED);
        });

        subscribe(socket, TOPIC_STATE_INSPECTION_RESULT, (data) => {
            const { action_name, ...payload } = data;
            setInspection(action_name);
            setInspectionPayload(payload);
        });

        subscribe(socket, TOPIC_NAV_STATE, (data) => {
            setNavState(data === STATE_NAV_STARTING);
        });
    }, [socket]);

    useEffect(() => {
        if (!missionState) return;
        setMissionStarting(missionState === STATE_MISSION_STARTING);
        setMissionPaused(missionState === STATE_MISSION_PAUSED);
    }, [missionState]);

    const clearMissionState = useCallback(() => {
        setMissionState(null);
    }, [setMissionState]);

    return (
        <MissionContext.Provider
            value={{
                recordState,
                setRecordState,
                recordPayload,
                missionState,
                setMissionState,
                missionPayload,
                missions,
                missionId,
                setMissionId,
                recordEnded,
                missionStarting,
                setMissionStarting,
                missionEnded,
                missionPaused,
                inspection,
                inspectionPayload,
                navState,
                listMission,
                deleteMission,
                renameMission,
                handleNavState,
                handleRename,
                handleDelete,
                clearMissionState,
                setNavState,
                setMapId,
            }}
        >
            {children}
        </MissionContext.Provider>
    );
}

export default withRouter(MissionProvider);
