import React, { useMemo, useState } from "react";
import { ARM_AUTO_GRIP } from "../lib/protocols/control";
import AirQBar from "./AirQBar";
import { useArmControl } from "./ArmControlProvider";
import { useArmState } from "./ArmStateProvider";
import BottomBar from "./BottomBar";
import styles from "./css/MainScreen.module.css";
import { useSnack } from "./SnackProvider";
import Streamer from "./Streamer";

export const MenuContext = React.createContext();
MenuContext.displayName = "Menu";
export const MenuConsumer = MenuContext.Consumer;

function MainScreen() {
    const { setSnack } = useSnack();
    const { hasArm, armMode } = useArmState();
    const [showIAQ, setShowIAQ] = useState(false);
    const {
        inArmControl,
        armManipMode,
        setArmManipMode,
        pointerPos,
        setPointerPos,
        handleAutoGrip,
        localStow,
        armIsMain,
    } = useArmControl();
    const menuActions = {
        showIAQ,
        toggleIAQ: () => setShowIAQ(!showIAQ),
    };
    // TODO: move handleAutomatic out of MainScreen. is it only for arm!
    const handleAutomatic = () => {
        if (armManipMode !== ARM_AUTO_GRIP) {
            setSnack({
                type: "success",
                message: "Can Touch To Object",
            });
            setArmManipMode(ARM_AUTO_GRIP);
            return;
        }
        if (armManipMode === ARM_AUTO_GRIP) {
            handleAutoGrip();
        }
    };
    const fullAutomaticWording = useMemo(() => {
        let res = "";
        armManipMode === ARM_AUTO_GRIP ? (res = `CONFIRM`) : (res = `FULL AUTOMATIC`);
        return res;
    }, [armManipMode]);
    const isShowAutomatic = !showIAQ && hasArm && armMode === "Unstow" && inArmControl && armIsMain;
    return (
        <>
            <div className={styles.container}>
                <div className={styles["stream-container"]}>
                    <Streamer></Streamer>
                </div>
            </div>
            <div className={`${styles["airq-bar"]} ${styles["text-center"]}`}>
                {isShowAutomatic && (
                    <button
                        className={styles["full-automatic"]}
                        onClick={() => handleAutomatic()}
                        disabled={armManipMode === ARM_AUTO_GRIP && !pointerPos}
                    >
                        {fullAutomaticWording}
                    </button>
                )}
                {showIAQ && <AirQBar isShow={showIAQ}></AirQBar>}
            </div>
            <MenuContext.Provider value={menuActions}>
                <BottomBar></BottomBar>
            </MenuContext.Provider>
        </>
    );
}

export default MainScreen;
