import { createContext, useContext, useState } from "react";

import { DISABLE_ACTION } from "../consts";

export const ActionStateContext = createContext();
ActionStateContext.displayName = "ActionState";
export const ActionStateConsumer = ActionStateContext.Consumer;
export const useActionState = () => useContext(ActionStateContext);

function ActionStateProvider({ children }) {
    const [disableAction, setDisableAction] = useState(DISABLE_ACTION);

    return (
        <ActionStateContext.Provider
            value={{
                disableAction,
                setDisableAction,
            }}
        >
            {children}
        </ActionStateContext.Provider>
    );
}

export default ActionStateProvider;
