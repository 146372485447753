import CameraProvider from "./CameraProvider";
import ControlSocketProvider from "./ControlSocketProvider";
import FullScreenProvider from "./FullScreenProvider";

import ActionStateProvider from "./ActionStateProvider";

// all providers below need socket from ControlSocketProvider
import AirQProvider from "./AirQProvider";
import ArmControlProvider from "./ArmControlProvider";
import ArmStateProvider from "./ArmStateProvider";
import BatteryProvider from "./BatteryProvider";
import GamepadProvider from "./GamepadProvider";
import InspectionProvider from "./InspectionProvider";
import MissionLogProvider from "./MissionLogProvider";
import MissionProvider from "./MissionProvider";
import NotificationProvider from "./NotificationProvider";
import RobotControlProvider from "./RobotControlProvider";
import RobotMovementProvider from "./RobotMovementProvider";
import RobotStateProvider from "./RobotStateProvider";
import WifiSwitchingProvider from "./WifiSwitchingProvider";
// import AudioProvider from "./AudioProvider";
import { HAS_INSPECTION } from "../consts";
import AudioProvider from "./AudioProvider";
import GpsProvider from "./GpsProvider";
import RobotErrorProvider from "./RobotErrorProvider";
import SnackProvider from "./SnackProvider";
import StreamControlProvider from "./StreamControlProvider";
import PTZControlProvider from "./PTZControlProvider";
import PTZStateProvider from "./PTZStateProvider";
import JanusWebRTCProvider from "./JanusWebRTCProvider";

// Wrap components one by one. The component in the front would be in top level, and the other way around
function Compose({ components = [], children }) {
    return (
        <>
            {components.reduceRight((children, Component) => {
                return <Component>{children}</Component>;
            }, children)}
        </>
    );
}

const inspectionProviders = HAS_INSPECTION ? [InspectionProvider] : [];

function Providers({ children }) {
    return (
        <Compose
            components={[
                SnackProvider,
                FullScreenProvider,
                CameraProvider,
                JanusWebRTCProvider,
                ActionStateProvider,
                ControlSocketProvider, // notice that providers below need this socket
                StreamControlProvider,
                RobotErrorProvider,
                GpsProvider,
                NotificationProvider,
                RobotStateProvider,
                RobotControlProvider,
                GamepadProvider,
                RobotMovementProvider,
                ArmStateProvider,
                ArmControlProvider,
                AirQProvider,
                BatteryProvider,
                WifiSwitchingProvider,
                MissionProvider,
                ...inspectionProviders,
                MissionLogProvider,
                AudioProvider,
                PTZStateProvider,
                PTZControlProvider,
            ]}
        >
            {children}
        </Compose>
    );
}

export default Providers;
