import React, { useMemo } from "react";
import { getCamera } from "../lib/protocols/streams";
import {
    STATE_STREAM_NONE,
    STATE_STREAM_OKAY,
    STATE_STREAM_SLOW,
    streamStateToMsg,
} from "../lib/state";
import { ReactComponent as IconStreamStatus } from "../svg/IconVideoStatus.svg";
import { CameraContext, CAM_VIEW_MAIN } from "./CameraProvider";
import styles from "./css/Streamer.module.css";

function StreamStatus({ camConnected = false, streamStatus }) {
    const { views } = React.useContext(CameraContext);
    const mainViewCamName = useMemo(
        () => getCamera({ source: views[CAM_VIEW_MAIN] })?.name || "Unknown",
        [views]
    );
    const videoStatus = () => {
        if (camConnected) {
            console.log(`streamStatus ${streamStatus}`);
            switch (streamStatus) {
                case STATE_STREAM_OKAY:
                    return (
                        <span style={{ color: "lime" }}>
                            {mainViewCamName} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
                case STATE_STREAM_SLOW:
                    return (
                        <span style={{ color: "yellow" }}>
                            {mainViewCamName} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
                case STATE_STREAM_NONE:
                default:
                    return (
                        <span style={{ color: "yellow" }}>
                            {mainViewCamName} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
            }
        } else {
            return <span style={{ color: "red" }}>Not connected</span>;
        }
    };
    return (
        <div className={`${styles.box}`}>
            <IconStreamStatus width="19px" height="19px"></IconStreamStatus>
            <span>Stream: {videoStatus()}</span>
        </div>
    );
}

export default React.memo(StreamStatus);
