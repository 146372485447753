import { useState } from "react";
import {
    ARM_AUTO_GRIP,
    ARM_CARRY,
    ARM_MANIP_MANUAL,
    ARM_MANIP_VALVE_TURN,
    gripperClose,
    gripperOpen,
    stow,
    unstow,
} from "../lib/protocols/control";
import { useArmControl } from "./ArmControlProvider";
import { useArmState } from "./ArmStateProvider";
import { useSocket } from "./ControlSocketProvider";
import OverlayButtonPanel from "./OverlayButtonPanel";

import { ReactComponent as IconCloseMenu } from "../svg/IconCloseMenu.svg";
import { ReactComponent as IconGateValve } from "../svg/IconGateValve.svg";
import { ReactComponent as IconGripperClosed } from "../svg/IconGripperClosed.svg";
import { ReactComponent as IconGripperOpened } from "../svg/IconGripperOpened.svg";
import { ReactComponent as IconHand } from "../svg/IconHand.svg";
import { ReactComponent as IconOpenDoor } from "../svg/IconOpenDoor.svg";
import { ReactComponent as IconSandSample } from "../svg/IconSandSample.svg";
import { ReactComponent as IconStow } from "../svg/IconStow.svg";
import { ReactComponent as IconUnstow } from "../svg/IconUnstow.svg";
import { ReactComponent as IconWaterTap } from "../svg/IconWaterTap.svg";

import styles from "./css/Streamer.module.css";

function ArmControlButtonPanel({ joystickSize, btnSize = 62 }) {
    const { armMode, isGripperOpen } = useArmState();
    const { inArmControl, setArmAction, armManipMode, setArmManipMode, isAutoGripMode } =
        useArmControl();
    const { hasArm } = useArmState();
    const { sendControl } = useSocket();
    const [showActionBar, setShowActionBar] = useState(false);
    const [stowing, setStowing] = useState(false);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const handleStow = async () => {
        setStowing(true);
        sendControl(gripperOpen());
        await sleep(200);
        sendControl(stow());
        await sleep(200);
        sendControl(gripperClose());
        setArmManipMode(ARM_MANIP_MANUAL);
        setStowing(false);
    };
    const handleUnStow = () => {
        sendControl(unstow());
        setArmManipMode(ARM_MANIP_MANUAL);
    };
    const buttonList = [
        {
            onClick: (e) => {
                if (armMode === "Stow" && !stowing) handleUnStow();
                if ((armMode === "Unstow" || armMode === "AutoGrasp") && !stowing) handleStow();
                // e.stopPropagation();
            },
            children: (
                <>
                    {(armMode === "Unstow" || armMode === "AutoGrasp") && !stowing && (
                        <>
                            <IconStow /> <span>Stow</span>
                        </>
                    )}
                    {armMode === "Stow" && !stowing && (
                        <>
                            <IconUnstow /> <span>Unstow</span>
                        </>
                    )}
                </>
            ),
        },
        {
            onClick: (e) => {
                if (armManipMode === ARM_MANIP_VALVE_TURN) setArmManipMode(ARM_MANIP_MANUAL);
                if (armManipMode === ARM_MANIP_MANUAL || armManipMode === ARM_AUTO_GRIP)
                    setArmManipMode(ARM_MANIP_VALVE_TURN);
            },
            children: (
                <>
                    {armManipMode === ARM_MANIP_VALVE_TURN && <IconGateValve />}
                    {(armManipMode === ARM_MANIP_MANUAL || armManipMode === ARM_AUTO_GRIP) && (
                        <IconHand />
                    )}
                    <span>
                        {armManipMode === ARM_MANIP_VALVE_TURN && `Valve`}
                        {(armManipMode === ARM_MANIP_MANUAL || armManipMode === ARM_AUTO_GRIP) &&
                            `Manual`}
                    </span>
                </>
            ),
            disabled: armMode === "Stow",
        },
        {
            onClick: (e) => {
                if (isGripperOpen) {
                    sendControl(gripperClose());
                }
                if (!isGripperOpen) {
                    sendControl(gripperOpen());
                }
                e.stopPropagation();
            },
            children: (
                <>
                    {isGripperOpen ? <IconGripperOpened /> : <IconGripperClosed />}
                    <span>Gripper</span>
                </>
            ),
        },
    ];
    const angleInDegrees = (count) => {
        return 135 / count;
    };

    const calcDegrees = (count = 3, index) => {
        let radius = joystickSize;
        let offsetToParentCenter = radius / 2; //assumes parent is square
        let offsetToChildCenter = btnSize / 2;
        let totalOffset = offsetToParentCenter - offsetToChildCenter;
        let y = Math.sin(angleInDegrees(count) * (index + count + 1) * (Math.PI / 180)) * radius;
        let x = Math.cos(angleInDegrees(count) * (index + count + 1) * (Math.PI / 180)) * radius;

        return {
            top: `${y + totalOffset}px`,
            left: `${x + totalOffset}px`,
        };
    };

    if (hasArm && inArmControl) {
        return (
            <>
                {/* <button
                    type="button"
                    disabled=isAutoGripMode
                    className={styles["in-screen-btn"]}
                    onClick={() => setShowActionBar(true)}
                >
                    <IconActions />
                    <span>Actions</span>
                </button> */}
                {buttonList.map(({ children, className, onClick, disabled }, index) => (
                    <button
                        type="button"
                        key={index}
                        className={`${className ?? ""} ${styles["in-screen-btn"]}`}
                        disabled={isAutoGripMode || disabled}
                        onClick={onClick}
                        style={{
                            ...calcDegrees(buttonList.length, index),
                            width: `${btnSize}px`,
                            height: `${btnSize}px`,
                        }}
                    >
                        {children}
                    </button>
                ))}
                {showActionBar && (
                    <OverlayButtonPanel
                        items={[
                            {
                                icon: IconWaterTap,
                                description: "Switch Water Tap",
                                action: () => {
                                    setArmAction({
                                        icon: IconWaterTap,
                                        iconType: "svg",
                                        action: "Switch Water Tap",
                                        actionDesc: "Click on the water tap to execute action",
                                        onExecute: () => console.log("execute switch water tap"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconOpenDoor,
                                description: "Open Door",
                                action: () => {
                                    setArmAction({
                                        icon: IconOpenDoor,
                                        iconType: "svg",
                                        action: "Open Door",
                                        actionDesc: "Click on the doorknob to execute action",
                                        onExecute: () => console.log("execute open door"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconSandSample,
                                description: "Source Sand",
                                action: () => {
                                    setArmAction({
                                        icon: IconSandSample,
                                        iconType: "svg",
                                        action: "Source Sand",
                                        actionDesc:
                                            "Place front sight in right position to execute action. ",
                                        onExecute: () => console.log("execute source sand"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconCloseMenu,
                                description: "Close Menu",
                                action: () => setShowActionBar(false),
                            },
                        ]}
                    />
                )}
            </>
        );
    } else {
        return <></>;
    }
}

export default ArmControlButtonPanel;
