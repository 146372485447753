import { useCallback, useEffect, useState } from "react";
import { ReactComponent as IconReset } from "../svg/IconReload.svg";
import styles from "./css/RangeSlider.module.css";

/**
 * @typedef RangeOptions
 * @type {object}
 * @property {number} min - min value of slider
 * @property {number} max - max value of slider
 * @property {number} [step=1] - step value of slider
 * @property {number} [value=0] - current value. Will be ignored if `controlled` is false
 * @property {number} [defaultValue=0] - default value of slider. Will be set as the value when first initialized / when reset button is clicked
 * @property {Boolean} [showIndicator=true] - show current value indicator
 * @property {Boolean} [controlled=true] - whether the input's value is decided by `value`.
 * @property {Boolean} [lazy=false] - onChange in react is always triggered even when mouse is not released. When lazy is true, onChange is only triggered after mouse / touch is released.
 * @property {Boolean} [precision=1] - number of decimal places to show in the indicator.
 *
 * @param {{name: string, value: number, action: function, options: RangeOptions}}
 */
function RangeSlider({ name, unit = "", onChange = null, options, disabled = false }) {
    const {
        min,
        max,
        step = 1,
        value: externalValue = 0,
        defaultValue = 0,
        showIndicator = true,
        controlled = true,
        lazy = false,
        precision = 1,
    } = options;
    const [internalValue, setValue] = useState(defaultValue);

    const mayBeOnChange = useCallback(
        (newVal) => {
            if (typeof onChange === "function") {
                console.log("onChange triggered");
                onChange(newVal);
            }
        },
        [onChange]
    );

    const setValueAndCallOnChange = useCallback(
        (value) => {
            const newVal = Number(value);
            setValue(newVal);
            mayBeOnChange(newVal);
        },
        [setValue, mayBeOnChange]
    );

    useEffect(() => {
        if (controlled) {
            setValue(externalValue);
        }
    }, [externalValue, controlled]);

    return (
        <div className={styles.container}>
            <h3 className={styles.label}>{name}</h3>
            <span className={styles.range}>
                <div className={`${styles["slider-wrapper"]}`}>
                    <input
                        type="range"
                        value={internalValue}
                        {...{ min, max, step }}
                        onChange={(e) => {
                            const newVal = Number(e.target.value);
                            setValue(newVal);
                            if (!lazy) {
                                mayBeOnChange(newVal);
                            }
                        }}
                        onMouseUp={(e) => setValueAndCallOnChange(e.target.value)}
                        onTouchEnd={(e) => setValueAndCallOnChange(e.target.value)}
                        disabled={disabled}
                    />
                </div>

                {showIndicator && (
                    <span className={styles.indicator}>
                        {internalValue.toFixed(precision)} {unit}
                    </span>
                )}
            </span>
            <button
                type="button"
                className={styles.reset}
                disabled={disabled}
                onClick={() => {
                    setValue(defaultValue);
                    onChange(defaultValue);
                }}
                onTouchEnd={(e) => {
                    setValue(defaultValue);
                    onChange(defaultValue);
                    e.preventDefault();
                }}
            >
                <IconReset width="10px" height="10px" />
            </button>
        </div>
    );
}

export default RangeSlider;
