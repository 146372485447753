import { useEffect, useState } from "react";
import styles from "./css/MissionTab.module.css";

import {
    missionRecordStart,
    missionRecordStop,
    missionRunPause,
    missionRunStart,
    missionRunStop,
} from "../lib/protocols/control";
import { callSpotService } from "../lib/service";

import { ReactComponent as IconPause } from "../svg/IconPause.svg";
import { ReactComponent as IconPlay } from "../svg/IconPlay.svg";
import { ReactComponent as IconRecord } from "../svg/IconRecord.svg";
import { ReactComponent as IconStop } from "../svg/IconStop.svg";
import Icon from "./Icon";

import {
    STATE_MISSION_GENERATED,
    STATE_MISSION_GENERATING,
    STATE_MISSION_GENERATING_FAILED,
    STATE_NAV_LOADING,
} from "../lib/state";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import { useMission } from "./MissionProvider";
import RecordSavePrompt from "./RecordSavePrompt";
import { useStreamControl } from "./StreamControlProvider";
import Switch from "./Switch";
import { useSocket } from "./ControlSocketProvider";

function VideoButtons({ type, selectedId, selectedMapId, disabled = true }) {
    const { missionEnded, navState, missionPaused, recordEnded, missionStarting, handleNavState } =
        useMission();
    const { videoIsRecording, startVideoRecording, stopVideoRecording } = useStreamControl();
    const [enableLog, setEnableLog] = useState(true);
    const { recordState, setRecordState } = useMission();
    const [showFinishRecord, setShowFinishRecord] = useState(false);
    const { sendControl } = useSocket();
    const startMissionRecord = () => sendControl(missionRecordStart());
    const stopMissionRecord = () => {
        sendControl(missionRecordStop());
    };

    const startMissionRun = (id, map_id) => {
        sendControl(missionRunStart(id, map_id));
    };
    const pauseMissionRun = () =>
        callSpotService(...missionRunPause()).catch((err) => console.error(err));
    const stopMissionRun = () =>
        callSpotService(...missionRunStop()).catch((err) => console.error(err));

    useEffect(() => {
        switch (recordState) {
            case STATE_MISSION_GENERATED:
            case STATE_MISSION_GENERATING:
            case STATE_MISSION_GENERATING_FAILED:
                setShowFinishRecord(true);
                break;
            default:
                setShowFinishRecord(false);
        }
    }, [recordState]);

    return (
        <>
            {/* TODO: refactor. this is just too much stuff in one button. please pass icon and onClick handler INTO this component instead of relying on this component to know what to do */}
            {type === "run" && (
                <>
                    <div className={styles["video-btn-wrapper"]}>
                        {missionStarting && (
                            <Icon
                                icon={LoadingSpinner}
                                type="svg"
                                width="1.5rem"
                                height="1.5rem"
                                className={styles.spinner}
                            ></Icon>
                        )}
                        {(missionEnded || missionPaused) && (
                            <button
                                disabled={!selectedId || disabled}
                                onClick={() => startMissionRun(selectedId, selectedMapId)}
                            >
                                <Icon icon={IconPlay} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                        {!missionPaused && !missionEnded && (
                            <button
                                disabled={missionPaused || !selectedId || disabled}
                                onClick={() => pauseMissionRun()}
                            >
                                <Icon icon={IconPause} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                        {!missionEnded && (
                            <button
                                disabled={missionEnded || !selectedId || disabled}
                                onClick={() => stopMissionRun()}
                            >
                                <Icon icon={IconStop} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                    </div>
                    {process.env.NODE_ENV === "development" && (
                        <>
                            <Switch
                                title="Enable Mission Logging"
                                isOn={enableLog}
                                onToggle={() => setEnableLog((enable) => !enable)}
                                disabled={!missionEnded}
                            ></Switch>
                            <p style={{ margin: 0, fontSize: "x-small" }}>
                                note: currently disabled and only visible in dev mode⬆️
                            </p>
                        </>
                    )}
                </>
            )}
            {type === "record" && (
                <div className={styles["video-btn-wrapper"]}>
                    <button
                        onClick={
                            recordEnded & (recordState !== STATE_NAV_LOADING)
                                ? startMissionRecord
                                : stopMissionRecord
                        }
                        disabled={disabled}
                    >
                        {recordState === STATE_NAV_LOADING ? (
                            <Icon
                                icon={LoadingSpinner}
                                type="svg"
                                width="1.5rem"
                                height="1.5rem"
                                className={styles.spinner}
                            ></Icon>
                        ) : recordEnded ? (
                            <Icon icon={IconRecord} type="svg" width="32px" height="32px" />
                        ) : (
                            <Icon icon={IconStop} type="svg" width="32px" height="32px" />
                        )}
                    </button>
                </div>
            )}
            {type === "video" && (
                <div className={styles["video-btn-wrapper"]}>
                    <button onClick={videoIsRecording ? stopVideoRecording : startVideoRecording}>
                        {videoIsRecording ? (
                            <Icon icon={IconStop} type="svg" width="32px" height="32px" />
                        ) : (
                            <Icon icon={IconRecord} type="svg" width="32px" height="32px" />
                        )}
                    </button>
                </div>
            )}
            {type === "nav" && (
                <div className={styles["video-btn-wrapper"]}>
                    <button onClick={handleNavState}>
                        {navState ? (
                            <Icon icon={IconStop} type="svg" width="32px" height="32px" />
                        ) : (
                            <Icon icon={IconPlay} type="svg" width="32px" height="32px" />
                        )}
                    </button>
                </div>
            )}
        </>
    );
}

export default VideoButtons;
