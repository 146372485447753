import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./css/LogHistory.module.css";
import EntityAbsence from "./EntityAbsence";
import Detail from "./LogHistoryDetail";
import IAQChart from "./LogHistoryIAQChart";
import ArtInspection from "./LogHistoryArtInspection";
import Inspection from "./LogHistoryInspection";
import Replay from "./LogHistoryReplay";
import Summary from "./LogHistorySummary";

import { HAS_INSPECTION } from "../consts";
import { download_table_as_csv } from "../lib/utils";
import LogHistoryErrorFallback from "./LogHistoryErrorFallback";
import { getKeywords, getMissionNameById, useLog } from "./MissionLogProvider";
import { useSnack } from "./SnackProvider";

const videosAreIncomplete = (videos) => videos.some((v) => !v.complete && !v.failed);

function LogHistory() {
    const { logId = "" } = useParams(); // id is the kebabcase of name
    const { logs, events, videos, IAQ, customActions, getLog, inspectionResults } = useLog();
    const [tabIndex, setTabIndex] = useState(0);
    const [title, setTitle] = useState("Mission not found");
    const { setSnack } = useSnack();
    const { fetchVideosToStore } = useLog();
    // set the types of detected objects displayed. null means all of them are displayed
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [hasIncompleteVideos, setHasIncompleteVideos] = useState(false);
    const refreshRef = useRef();

    // call before content is displayed
    useLayoutEffect(() => {
        if (logId === "") return;
        const name = getMissionNameById(logs, logId);
        name && setTitle(name);
        getLog(logId).then(() => {
            setTabIndex(0);
        });
    }, [logId]);

    useEffect(() => {
        const kwRegex = new RegExp(`.*${selectedKeywords.map((kw) => `(${kw})`).join("|")}.*`, "i");
        setFilteredEvents(
            selectedKeywords.length === 0
                ? events
                : events.filter(
                      (e) => e.type?.match(kwRegex) || JSON.stringify(e.data)?.match(kwRegex)
                  )
        );
    }, [selectedKeywords, events]);

    useEffect(() => {
        if (!logId) {
            return;
        }
        clearTimeout(refreshRef.current);
        const isIncomplete = videosAreIncomplete(videos);
        const hasNoVideos = videos.length === 0;
        if (isIncomplete || hasNoVideos) {
            refreshRef.current = setTimeout(() => {
                fetchVideosToStore(logId);
            }, 3000);
        }
        setHasIncompleteVideos(isIncomplete);
    }, [logId, videos]);

    let content;
    if (logId)
        content = (
            <div className={styles["container"]}>
                <header className={styles["header"]}>
                    <h1 className={styles["title"]}>{title}</h1>
                    <button
                        type="button"
                        className={styles["export-btn"]}
                        onClick={() => download_table_as_csv("mission-events-details")}
                    >
                        Export CSV
                    </button>
                </header>

                <div className={styles["tab-container"]}>
                    <button
                        type="button"
                        className={styles["tab"]}
                        data-active={tabIndex === 0 ? "1" : "0"}
                        onClick={() => setTabIndex(0)}
                    >
                        Log Overview
                    </button>

                    <button
                        type="button"
                        className={styles["tab"]}
                        data-active={tabIndex === 1 ? "1" : "0"}
                        onClick={() => setTabIndex(1)}
                        title={videos.length <= 0 ? "No videos available" : ""}
                    >
                        Video Replay
                    </button>

                    <button
                        type="button"
                        className={styles["tab"]}
                        title={IAQ.length <= 0 ? "No IAQ data available" : ""}
                        data-active={tabIndex === 2 ? "1" : "0"}
                        onClick={() => setTabIndex(2)}
                    >
                        IAQ Chart
                    </button>
                    {HAS_INSPECTION && (
                        <div>
                            <button
                                type="button"
                                className={styles["tab"]}
                                title={"Art inspection"}
                                data-active={tabIndex === 3 ? "1" : "0"}
                                onClick={() => setTabIndex(3)}
                            >
                                Art Inspection
                            </button>

                            <button
                                type="button"
                                className={styles["tab"]}
                                title={"Inspection"}
                                data-active={tabIndex === 4 ? "1" : "0"}
                                onClick={() => setTabIndex(4)}
                            >
                                Inspection
                            </button>
                        </div>
                    )}
                </div>

                <Summary
                    isShow={tabIndex === 0}
                    keywords={getKeywords(events)}
                    selectedKeywords={selectedKeywords}
                    setSelectedKeywords={setSelectedKeywords}
                />

                <Replay
                    isShow={tabIndex === 1}
                    videos={videos}
                    hasIncompleteVideos={hasIncompleteVideos}
                />

                <IAQChart isShow={tabIndex === 2} IAQData={IAQ} />

                <ArtInspection isShow={tabIndex === 3} events={customActions}></ArtInspection>
                <Inspection isShow={tabIndex === 4} events={inspectionResults}></Inspection>
                <Detail
                    isShow={tabIndex === 0}
                    events={filteredEvents}
                    selectedKeywords={selectedKeywords}
                />
            </div>
        );
    else
        content = (
            <div className={styles.container}>
                <EntityAbsence
                    title={"No Selected Log Yet"}
                    btnText={"Select a Log on the left"}
                    path={"/main/logs"}
                ></EntityAbsence>
            </div>
        );
    return <LogHistoryErrorFallback>{content}</LogHistoryErrorFallback>;
}

export default LogHistory;
