import React, { useCallback, useContext, useEffect, useState } from "react";
import { DEFAULT_PTZ_STATE } from "../consts";
import { ptzCtrl, inspectPTZImage, getPTZFrame } from "../lib/protocols/control";
import { callService } from "../lib/service";
import { subscribe, unsubscribe } from "../lib/socket";

import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

export const PTZControlContext = React.createContext();
PTZControlContext.displayName = "PTZControl";
export const PTZControlConsumer = PTZControlContext.Consumer;
export const usePTZControl = () => useContext(PTZControlContext);

const TopicPTZInspectionResults = "/ptz_inspection_results";

function PTZControlProvider({ children }) {
    // Connection
    const { controlSocket: socket, sendControl } = useSocket();

    // Snack
    const { addToast } = useSnack();

    // PTZ variable
    const [inControl, setInControl] = useState(false);
    const [movement, setMovement] = useState({
        pan: DEFAULT_PTZ_STATE.pan,
        tilt: DEFAULT_PTZ_STATE.tilt,
        zoom: DEFAULT_PTZ_STATE.zoom,
    });

    // -> Image
    const [showPreview, setShowPreview] = useState(false);
    const [previewImageURL, setPreviewImageURL] = useState("");
    const [isInspectionLoading, setIsInspectionLoading] = useState(false);
    const [isGetFrameLoading, setIsGetFrameLoading] = useState(false);

    // Function
    // -> Error handler
    const errHandler = (e, message) => {
        console.error("Error occurred. message: ", message, "; error thrown: ", e);
        addToast({
            type: "error",
            message: message,
            duration: 7000,
        });
    };

    // -> PTZ movement
    const changeMovement = useCallback(
        ({ angle, value }) => {
            // Update movement
            setMovement((prev) => ({ ...prev, [angle]: value }));
        },
        [setMovement]
    );

    const sendMovement = useCallback(
        (movement) => {
            sendControl(ptzCtrl(movement));
        },
        [sendControl]
    );

    // -> Inspection PTZ image
    const inspectImage = () => {
        setIsInspectionLoading(true);
        setShowPreview(true);

        callService(...inspectPTZImage(movement))
            .then((response) => {
                const { errCode, errMsg, runID } = response;

                if (errCode) throw new Error(errMsg);
            })
            .catch((e) => {
                errHandler(e, `Failed to get inspection result : ${e.message || "Unknown error"}`);

                setPreviewImageURL("");
                setShowPreview(false);
            })
            .finally(() => {
                setIsInspectionLoading(false);
            });
    };

    // -> Preview PTZ image
    const getFrame = () => {
        setIsGetFrameLoading(true);
        setShowPreview(true);

        callService(...getPTZFrame())
            .then((response) => {
                const { errCode, errMessage, image } = response;

                if (errCode) throw new Error(errMessage);

                const url = `data:image/jpeg;base64, ${image}`;
                setPreviewImageURL(url);
            })
            .catch((e) => {
                errHandler(e, `Failed to get preview image: ${e.message || "Unknown error"}`);

                setShowPreview(false);
            })
            .finally(() => {
                setIsGetFrameLoading(false);
            });
    };

    // Update
    // -> Movement
    useEffect(() => {
        if (!inControl) return;
        sendMovement(movement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [movement]);

    useEffect(() => {
        const inspectionResultHandler = (response) => {
            const { status, image, metadata, results } = response;

            const url = `data:image/jpeg;base64, ${image.data}`;
            setPreviewImageURL(url);

            addToast({
                type: "info",
                message: `Status: ${status} - Angle: ${JSON.parse(results).Angle}`,
                duration: 5000,
            });
        };

        subscribe(socket, TopicPTZInspectionResults, inspectionResultHandler);

        return () => {
            socket && unsubscribe(socket, TopicPTZInspectionResults, inspectionResultHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <PTZControlContext.Provider
            value={{
                inPTZControl: inControl,
                setInPTZControl: setInControl,
                ...movement,
                changePTZMovement: changeMovement,
                sendPTZMovement: sendMovement,
                // Preview image
                showPTZPreview: showPreview,
                setShowPTZPreview: setShowPreview,
                PTZPreviewImage: previewImageURL,
                // Inspection
                isPTZInspectionLoading: isInspectionLoading,
                inspectPTZImage: inspectImage,
                // Get frame
                isGetPTZFrameLoading: isGetFrameLoading,
                getPTZFrame: getFrame,
            }}
        >
            {children}
        </PTZControlContext.Provider>
    );
}

export default PTZControlProvider;
