import { ARM_STOW, MODE_SIT } from "./lib/protocols/control";
import {
    STATE_BATTERY_UNKNOWN,
    STATE_CLAIM_UNKNOWN,
    STATE_MOTOR_UNKNOWN,
    STATE_ROBOT_DISCONNECTED,
} from "./lib/state";

export const DEFAULT_HOST =
    process.env.REACT_APP_JETPACK_HOST_OVERRIDE || `${window.location.hostname}`;
export const DEFAULT_CONTROL_PORT = process.env.REACT_APP_JETPACK_CONTROL_PORT_OVERRIDE || 3000;
export const DEFAULT_VIDEO_PORT = process.env.REACT_APP_JETPACK_VIDEO_PORT_OVERRIDE || 9002;
export const DEFAULT_SPEAKER_PORT = process.env.REACT_APP_JETPACK_SPEAKER_PORT_OVERRIDE || 9003;
export const DEFAULT_WEBSERVER_PORT =
    process.env.REACT_APP_JETPACK_WEBSERVER_PORT_OVERRIDE || window.location.port;

export const COMPACT_MODE = JSON.parse(process.env.REACT_APP_COMPACT_MODE) || false;

export const SPEED_SLOW = 0.2;
export const SPEED_NORMAL = 1.0;
export const SPEED_FAST = 2;
export const SPEED_STEP = 0.2;

export const HEIGHT_ACTUAL = 0.61;
export const HEIGHT_DEFAULT = 0;
export const HEIGHT_MIN = -0.09;
export const HEIGHT_MAX = 0.09;
export const HEIGHT_STEP = 0.03;
export const ARM_TORQUE_MIN = 0;
export const ARM_TORQUE_MAX = 20.0;
export const ARM_TORQUE_STEP = 1;
export const ARM_GRIP_DEPTH_MIN = 0.1;
export const ARM_GRIP_DEPTH_MAX = 0.7;
export const ARM_GRIP_DEPTH_STEP = 0.1;
export const LIGHT_DEFAULT = 0;
export const LIGHT_MIN = 0;
export const LIGHT_MAX = 100;
export const LIGHT_STEP = 1;

export const AVOID_DEFAULT = 0.2;
export const AVOID_MIN = 0.05;
export const AVOID_MAX = 1.0;
export const AVOID_STEP = 0.05;

export const LOGIN_NOT_CONNECTED = 0;
export const LOGIN_CONNECTING = 1;
export const LOGIN_CONNECTED = 2;

export const STREAM_LOW_FPS = 5;

export const DISABLE_ACTION = true;

export const DEFAULT_ARM_STATE = {
    armMode: ARM_STOW,
    armJointPos: null,
    armGripperPos: {},
    hasArm: false,
    armGripDepth: 0.3,
    armTorque: 4,
    armEndEffectorState: {},
};

export const DEFAULT_PTZ_STATE = {
    hasPTZ: true,
    pan: 90,
    tilt: 90,
    zoom: 0.0,
};

export const DEFAULT_ROBOT_STATE = {
    robotMode: MODE_SIT,
    robotHeight: 0,
    robotConnectionState: STATE_ROBOT_DISCONNECTED,
    motorState: STATE_MOTOR_UNKNOWN,
    estopState: { software: false, hardware: false },
    maxSpeed: 0,
    batteryPercent: 0,
    batteryStatus: STATE_BATTERY_UNKNOWN,
    batteryTemperature: 0,
    motorClaimState: STATE_CLAIM_UNKNOWN,
    eStopClaimState: STATE_CLAIM_UNKNOWN,
    proximities: [],
};

export const HAS_INSPECTION = JSON.parse(process.env.REACT_APP_HAS_INSPECTION);
